import React from 'react';
import { UNIMED_URL } from '../../../constant/index';

import logoUnimed from '../../../assets/svg/unimed.svg';

import './footer.style.scss';

const getDomainLogo = () => (logoUnimed);

const Footer = () => (
  <div className="footer">
    <a
      href={UNIMED_URL}
      style={{ background: `url(${getDomainLogo()}) no-repeat center` }}
    >
      logo
    </a>
  </div>
);

export default Footer;
