import React, { useState } from 'react';
import { UNIMED_URL } from '../../../constant/index';

import logoUnimed from '../../../assets/svg/unimed.svg';

import './header.style.scss';

const Header = ({ isMobile, headerFixed }) => {
  const [MobileMenuIsVisible, setMobileMenuIsVisible] = useState(false);
  const [isBackdropActive, setIsBackdropActive] = useState(false);

  const domainLogo = `url(${logoUnimed}) no-repeat center`;

  const handleBackdropClick = () => {
    setIsBackdropActive(false);
    setMobileMenuIsVisible(false);
  };

  return (
    <div className="header-component">
      <div className={`side-menu ${MobileMenuIsVisible ? 'active' : ''}`}>
        <div className="box-header">
          <a href={UNIMED_URL} style={{ background: domainLogo }}>logo</a>
        </div>
      </div>
      <div className="container">
        <div className="header-container">
          <div className="container">
            <div className="box-header">
              <a href={UNIMED_URL} style={{ background: domainLogo }}>logo</a>
            </div>
          </div>
        </div>
      </div>
      <div className={`floating-header container ${headerFixed ? 'fixed-header' : ''}`}>
        <div className="header-container">
          <div className="container">
            <div className="box-header">
              <a href={UNIMED_URL} style={{ background: domainLogo }}>logo</a>
            </div>
          </div>
        </div>
      </div>
      <div
        role="none"
        className={`backdrop ${isBackdropActive ? 'active' : ''}`}
        onClick={handleBackdropClick}
      />
    </div>
  );
};

export default Header;
