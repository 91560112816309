import React, { lazy, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import './App.css';

import Header from './components/main/header/header.component';
import Footer from './components/main/footer/footer.component';
import ErrorBoundary from './components/common/error-boundary/error-boundary.component';
import Loading from './components/common/loading/loading.component';
import { RECAPTCHA_URL } from './constant';

const SelectContractType = lazy(() =>
  import('./pages/selectContractType/select-contract-type.component'),
);

const RegisterInitPage = lazy(() =>
  import('./pages/registerInit/register-init.component'),
);

const RegisterInitPagePerson = lazy(() =>
  import('./pages/registerInitPerson/register-init-person.component'),
);

const Wrapper = lazy(() => import('./components/entity/wrapper.component'));

const PersonWrapper = lazy(() =>
  import('./components/person/wrapper.component'),
);

const ContractResubmition = lazy(() =>
  import('./pages/contractResubmission/contract-resubmition.component'),
);

const Success = lazy(() => import('./pages/success/success.component'));

const Q2BankPage = lazy(() => import('./pages/appQ2Bank/appQ2Bank.component'));

const BlackListed = lazy(() =>
  import('./pages/Blacklisted/entire-period.component'),
);

const BLockedBy6Mon = lazy(() =>
  import('./pages/Blacklisted/by-6-months.component'),
);

const NotFound = lazy(() => import('./pages/NotFound/not-found.component'));

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      headerFixed: false,
      dimensions: {
        width: 0,
        height: 0,
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    let scroll = 0;
    let lastScrollPosition = 0;

    window.addEventListener('scroll', (e) => {
      scroll = window.scrollY;

      if (lastScrollPosition > 150 && scroll < lastScrollPosition) {
        _this.setState({ headerFixed: true });
      } else {
        _this.setState({ headerFixed: false });
      }
      lastScrollPosition = scroll;
    });

    // _this.toggleTheme();
  }

  updateWindowDimensions() {
    this.setState(
      { dimensions: { width: window.innerWidth, height: window.innerHeight } },
      () => {
        const { dimensions } = this.state;
        if (dimensions.width < 768) {
          this.setState({ isMobile: true });
        } else {
          this.setState({ isMobile: false });
        }
      },
    );
  }

  render() {
    const { isMobile, headerFixed } = this.state;
    return (
      <HelmetProvider>
        <Helmet>
          <script
            src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_URL}`}
          />
        </Helmet>
        <Header isMobile={isMobile} headerFixed={headerFixed} />
        <main className="main">
          <BrowserRouter>
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route
                    exact
                    path="/tipo-de-contrato/:orderValue"
                    element={<SelectContractType />}
                  />
                  <Route
                    exact
                    path="/pessoa-juridica/:orderValue"
                    element={<RegisterInitPage />}
                  />
                  <Route
                    exact
                    path="/pessoa-fisica/:orderValue"
                    element={<RegisterInitPagePerson />}
                  />
                  <Route
                    exact
                    path="/auto-cadastro/:initialToken/:initialLeadId"
                    element={<Wrapper />}
                  />
                  <Route
                    exact
                    path="/auto-cadastro/pessoa-fisica/:initialToken/:initialLeadId"
                    element={<PersonWrapper />}
                  />
                  <Route
                    exact
                    path="/segunda-via-contrato/:cnpj"
                    element={<ContractResubmition />}
                  />
                  <Route
                    exact
                    path="/sucesso/:hash/:leadId"
                    element={<Success />}
                  />
                  <Route
                    exact
                    path="/unimedpayapp"
                    element={<Q2BankPage />}
                  />
                  <Route
                    exact
                    path="/restrictive-list"
                    element={<BlackListed />}
                  />
                  <Route
                    exact
                    path="/blocked-by-6-months"
                    element={<BLockedBy6Mon />}
                  />
                  <Route element={<NotFound />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
        </main>
        <Footer />
      </HelmetProvider>
    );
  }
}

export default App;
