import React from 'react';

import './error-boundary.style.scss';

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
  }

  render() {
    const { children, hasError } = this.props;

    if (hasError) {
      return (
        <div className="error-image-overlay">
          <div className="error-image-container" />
          <h2 className="error-text">Houston, parece que temos um problema.</h2>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
