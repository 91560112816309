const FRANCHISES = Object.freeze({
  Q2_PAY_FRANCA: '20',
  BEBLUE: '77',
  OMNI_BEBLUE: '78',
  Q2_PAY_RIBEIRAO_PRETO: '79',
  Q2_PAY_SUL: '80',
  OITO_DIGITAL_TETO_PAGAMENTOS: '81',
  DR_FAROL_FRANCHISING: '82',
  SKYLINE_INTERMEDIACAO: '83',
  Q2_PAY_CAMPINAS: '84',
  REAL_FINTECH: '88',
  Q2_PAY_SAO_PAULO: '89',
  BEBLUE_SUB: '95',
  BEBLUE_PLUS_GESTOR: '98',
  AU5: '101',
  UNIMED_PAY: '106',
});

const API_URL = process.env.REACT_APP_FINANCIAL_API_URL;
const UNIMED_URL = process.env.REACT_APP_UNIMED_URL;
const RECAPTCHA_URL = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export { API_URL, FRANCHISES, UNIMED_URL, RECAPTCHA_URL };
